import LoginHeader from "../components/All Headers/loginHeader/LoginHeader";


const LearnMore = ()=>{
    return(
        <>
        <LoginHeader/>
        <div className="flex justify-center">
        <img src="/assets/images/tutorial.jpg"/>
        </div>
        </>
    )
}
export default LearnMore;